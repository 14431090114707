<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		class: string;
		width: string;
		height: string;
	}>(),
	{
		class: '',
		width: '360',
		height: '480',
	},
);
</script>
<template>
	<svg
		:width="props.width"
		:height="props.height"
		viewBox="0 0 360 480"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		:class="props.class"
	>
		<mask
			id="mask0_1276_1673"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="-60"
			y="0"
			width="480"
			height="480"
		>
			<rect
				width="480"
				height="480"
				transform="translate(-60)"
				fill="url(#paint0_radial_1276_1673)"
			/>
		</mask>
		<g mask="url(#mask0_1276_1673)">
			<circle cx="180" cy="240" r="47.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="79.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="111.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="143.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="143.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="175.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="207.5" stroke="#E5E5E5" />
			<circle cx="180" cy="240" r="239.5" stroke="#E5E5E5" />
		</g>
		<defs>
			<radialGradient
				id="paint0_radial_1276_1673"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(240 240) rotate(90) scale(240 240)"
			>
				<stop />
				<stop offset="1" stop-opacity="0" />
			</radialGradient>
		</defs>
	</svg>
</template>
